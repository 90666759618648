<template>
	<div class="customerHome interestspackage">
		<div class="chuck">
			<div class="title">
				权益使用记录
			</div>
			<Table style="margin-top: 20px;padding-bottom: 60px;" no-data-text='使用记录为空' stripe :columns="configColumns"
				:data="configData"></Table>
				<Page style='margin-top: 30px;' :page-size='size' :current='current' :total="total" @on-change='pageChange' />
		</div>
	</div>
</template>

<script>
	import {data_config_historyPage} from '../../api/customerApi.js'
	import util from '../../utils/utils2.js'
	export default {
		name: 'interestspackage',
		data(){
			return{
				current:1,
				size:10,
				total:1,
				configData:[],
				configColumns:[{
					title: '编号',
					key: 'requireUid',
				},{
					title: '需求/项目名称',
					key: 'requireName',
				},{
					title: '提交时间',
					key: 'requireApplyTime',
					render: (h, params) => {
						return h(
							"div",
							util.formatDateFmt(
								new Date(params.row.requireApplyTime),
								"yyyy-MM-dd"
							)
						);
					},
				},{
					title: '工作量（人/天)',
					key: 'configWorkload',
				},{
					title: '结案时间',
					key: 'requireCloseTime',
					render: (h, params) => {
						return h(
							"div",
							util.formatDateFmt(
								new Date(params.row.requireCloseTime),
								"yyyy-MM-dd"
							)
						);
					},
				},]
			}
		},
		computed: {
			customerInfo() {
				let str = localStorage.getItem('customerInfo')
				return JSON.parse(str)
			},	
		},
		mounted() {
			this.init()
		},
		methods:{
			init(){
				this.getConfigDetail(1)
			},
			async getConfigDetail(current){
				let params = {
					current:current,
					size:this.size,
					customerId:this.customerInfo.id
				}
				let data = await data_config_historyPage(params)
				if(data){
					this.configData = data.records
					this.current = data.current
					this.total = data.total
				}
				
			},
			pageChange(e){
				this.getConfigDetail(e)
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/style/customerorder.scss";
	.chuck{
		min-height: calc(100vh - 120px);
	}
</style>